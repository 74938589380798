import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { ClefInfoComponent } from '../../subcomponents/clef-info/clef-info.component';
import { ChordDifficultyInfoComponent } from '../../subcomponents/difficulty/chord-difficulty-info/chord-difficulty-info.component';
import { HeaderInfoComponent } from '../../subcomponents/header-info/header-info.component';
import { ScoreInfoComponent } from '../../subcomponents/score-info/score-info.component';
import { TimerInfoComponent } from '../../subcomponents/timer-info/timer-info.component';
import { Information } from '../../information';
import { NoteDifficultyInfoComponent } from '../../subcomponents/difficulty/note-difficulty-info/note-difficulty-info.component';

@Component({
  selector: 'app-staff-identification-notes-info',
  standalone: true,
  imports: [HeaderInfoComponent, NoteDifficultyInfoComponent, TimerInfoComponent, ScoreInfoComponent, ClefInfoComponent],
  templateUrl: './staff-identification-notes-info.component.html',
  styleUrl: './../../information.component.css'
})
export class StaffIdentificationNotesInfoComponent extends Information{
    constructor(@Inject(MAT_DIALOG_DATA) payload: any, dialog: MatDialog){
        super(payload, dialog);
        this.child = StaffIdentificationNotesInfoComponent;
        
    }


}
