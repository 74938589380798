import { Component, computed, ElementRef, HostListener, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { MenuDataService } from '../data-services/MenuDataService';
import { RouteConstants } from '../constants/RouteConstants';
import { GameId } from '../constants/GameIdEnum';
import { NavBarComponent } from "../nav-bar/nav-bar.component";


export type MenuItem = {
    id: number,
    icon: string,
    label: string,
    route: string,
    children: MenuItem[]
}

@Component({
    selector: 'app-side-menu',
    standalone: true,
    templateUrl: './side-menu.component.html',
    styleUrl: './side-menu.component.css',
    imports: [CommonModule, MatSidenavModule, MatToolbarModule, MatListModule,
        MatButtonModule, MatIconModule, MatMenuModule, RouterOutlet, NavBarComponent, RouterModule]
})
export class SideMenuComponent {
    public menuToggled: boolean = false;
    public earTrainingChildrenHidden: boolean = false;
    public staffIdentificationChildrenHidden: boolean = false;
    public staffBuildingChildrenHidden: boolean = false;

    constructor(private router: Router, private dataService: MenuDataService, private eRef: ElementRef){}
    
    
    ngOnInit() {
        this.dataService.booleanValue$.subscribe((value) => {
            this.collapsed.set(value);
        });
    }


    //Ear training children items
    menuItems_earTrainingChildren = signal<MenuItem[]>([
        {
            id: GameId.EarTraining_Intervals,
            icon: '',
            label: 'Identify Intervals',
            route: RouteConstants.INTERVAL_EAR_TRAINING_MENU,
            children: []
        },
        {
            id: GameId.EarTraining_Chords,
            icon: '',
            label: 'Identify Chords',
            route: RouteConstants.CHORD_EAR_TRAINING_MENU,
            children: []
        },
        {
            id: GameId.EarTraining_Scales,
            icon: '',
            label: 'Identify Scales',
            route: RouteConstants.SCALE_EAR_TRAINING_MENU,
            children: []
        }
    ])

    //Scale identification children items
    menuItems_staffIdentificationChildren = signal<MenuItem[]>([
        {
            id: GameId.StaffIdentification_Notes,
            icon: '',
            label: 'Identify Notes',
            route: RouteConstants.NOTE_STAFF_IDENTIFICATION_MENU,
            children: []
        },
        {
            id: GameId.StaffIdentification_Intervals,
            icon: '',
            label: 'Identify Intervals',
            route: RouteConstants.INTERVAL_STAFF_IDENTIFICATION_MENU,
            children: []
        },
        {
            id: GameId.StaffIdentification_Chords,
            icon: '',
            label: 'Identify Chords',
            route: RouteConstants.CHORD_STAFF_IDENTIFICATION_MENU,
            children: []
        },
        {
            id: GameId.StaffIdentification_Scales,
            icon: '',
            label: 'Identify Scales',
            route: RouteConstants.SCALE_STAFF_IDENTIFICATION_MENU,
            children: []
        }
    ])

    //Scale building children items
    menuItems_staffBuildingChildren = signal<MenuItem[]>([
        {
            id: 2,
            icon: '',
            label: 'Build Intervals',
            route: RouteConstants.INTERVAL_STAFF_BUILDING_MENU,
            children: []
        },
        {
            id: 0,
            icon: '',
            label: 'Build Chords',
            route: RouteConstants.CHORD_STAFF_BUILDING_MENU,
            children: []
        },
        {
            id: 1,
            icon: '', 
            label: 'Build Scales',
            route: RouteConstants.SCALE_STAFF_BUILDING_MENU,
            children: []
        }
    ])

    //Main navigation items
    menuItems = signal<MenuItem[]>([
        {
            id: 0,
            icon: 'home',
            label: 'Home',
            route: '',
            children: []
        }, 
        {
            id: 1,
            icon: 'format_list_numbered',
            label: 'Leaderboard',
            route: RouteConstants.LEADERBOARD,
            children: []
        },
        {
            id: 2,
            icon: 'hearing',
            label: 'Ear Training',
            route: '',
            children: this.menuItems_earTrainingChildren()
        },
        {
            id: 3,
            icon: 'visibility',
            label: 'Staff Identification',
            route: '',
            children: this.menuItems_staffIdentificationChildren()

        },
        {
            id: 4,
            icon: 'view_compact',
            label: 'Staff Building',
            route: '',
            children: this.menuItems_staffBuildingChildren()
        },
        {
            id: 5,
            icon: 'subject',
            label: 'About Us', 
            route: RouteConstants.ABOUT_US,
            children: []
        },
        {
            id: 6,
            icon: 'code',
            label: 'Changelog',
            route: RouteConstants.CHANGELOG,
            children: []
        }
    ]);
    
    collapsed = signal(false);
    sideNavWidth = '0px';
    // sideNavWidth = computed(() => this.collapsed() ? '100px' : '0px');

    //Handles the unique logic for each nav item when clicked
    // @HostListener('document:click', ['$event'])
    public handleClick(item: MenuItem): void{
        if(item.route === '' && item.id !== 0 ){
            return;
        }

        //Close the navbar if user clicks outside of it
        // const clickedInside = this.eRef.nativeElement.contains(event.target);
        // if (!clickedInside && !this.collapsed()) {
        //     // Collapse the sidenav if a click occurs outside of it
        //     this.collapsed.set(true);
        // }
        this.dataService.updateData(false);
        this.router.navigate([item.route]);
    }

    //Handles the navigation logic for each child element that is clicked
    public navigateTo(item: MenuItem): void{
        this.dataService.updateData(false);
        this.router.navigate([item.route]);
    }

    //Hides or displays the children items when the parent div is clicked
    public isChildDisplayed(id: number): boolean{
        switch(id){
            case 2: //ear training
                return this.earTrainingChildrenHidden;
            case 3:
                return this.staffIdentificationChildrenHidden;
            case 4:
                return this.staffBuildingChildrenHidden;
            default:
                return false;
                
        }
    }
}
