import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { StaffGameHandlerComponent } from '../../staff-game-handler/staff-game-handler.component';
import { GameId } from '../../../constants/GameIdEnum';
import { StaffBuilder } from '../../../staff-graphics/StaffBuilder';
import { Staff } from '../../../staff-graphics/models/Staff';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedDataService } from '../../../util/SharedDataService';
import { StaffScale } from '../../../staff-graphics/models/StaffScale';
import { Scale } from '../../../models/Scale';

@Component({ 
  selector: 'app-staff-identification-scale-game',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './../../staff-game-handler/staff-game-handler.component.html',
  styleUrls: ['./../../staff-game-handler/staff-game-handler.component.css', "./staff-identification-scale-game.component.css", 
    './../../game-handler.component.css'
  ]
})
export class StaffIdentificationScaleGameComponent extends StaffGameHandlerComponent{
    public override gameId = GameId.StaffIdentification_Scales;
    override initialCountdown: number = 300;
    private staff: Staff = new Staff([4, 4], []);

    constructor(protected override router: Router, protected override sharedDataService: SharedDataService, protected override dialog: MatDialog, 
                protected override route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: Object){
        super(router, sharedDataService, dialog, route);
    }

    override ngOnInit(): void {
        this.initModule();
    }

    //Runs after view has initialized
    async ngAfterViewInit(){
        if(isPlatformBrowser(this.platformId)){
            this.setCanvasSize(); 
            this.builder = new StaffBuilder(this.staff, this.canvas.nativeElement, this.gameId);
            const scale: Scale = this.generateRandomScale(this.isTrebleClef);
            this.randomElement = new StaffScale(scale, this.canvas.nativeElement);
            await this.builder.loadFont();
            this.builder.drawSimpleStaff(this.randomElement, this.isTrebleClef);

            //Reload after NotoMusic font loads
            setTimeout(async () =>{
                this.canvas.nativeElement.getContext('2d')?.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
                await this.builder.drawSimpleStaff(this.randomElement, this.isTrebleClef);
            }, 700);

        }
    }

    //Handles guess logic
    protected override check(guess: number | string, buttonRef: HTMLButtonElement){
        this.checkAnswer(guess, buttonRef);
    }

}
