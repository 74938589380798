import { Component } from '@angular/core';

@Component({
  selector: 'app-controls-info',
  standalone: true,
  imports: [],
  templateUrl: './controls-info.component.html',
  styleUrl: './controls-info.component.css'
})
export class ControlsInfoComponent {

}
