<div #infoContainer class="info-container">
    <app-header-info></app-header-info>
    <p>When the game starts, a random scale quality will be generated at the top of the screen, and the first note of the 
        scale will be drawn.  Given the root note and the scale quality, build out the rest of the scale.  
        The scale will always be ascending.  
        When you answer correctly, a new scale quality will be generated and drawn to the screen.
        The scale will not change until you answer correctly, no matter how many incorrect responses are submitted.
    </p>


    <div class="gif-image-container">
        <img src="assets/information/GIFs/SB_Scales.gif">
    </div>
    <app-controls-info></app-controls-info>
    <app-scale-difficulty-info></app-scale-difficulty-info> 
    <app-timer-info></app-timer-info>
    <app-score-info></app-score-info>
    <app-clef-info></app-clef-info>
    <div class="auth-control-container">
        <a (click)="close()">Close</a>
    </div>

</div>
