import { Router } from "@angular/router";
import { GameId } from "../constants/GameIdEnum";
import { RouteConstants } from "../constants/RouteConstants";
import { InformationComponent } from "../information/information.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { StaffIdentificationChordsInfoComponent } from "../information/staff-identification/staff-identification-chords-info/staff-identification-chords-info.component";
import { EarTrainingChordsInfoComponent } from "../information/ear-training/ear-training-chords-info/ear-training-chords-info.component";
import { EarTrainingIntervalsInfoComponent } from "../information/ear-training/ear-training-intervals-info/ear-training-intervals-info.component";
import { EarTrainingScalesInfoComponent } from "../information/ear-training/ear-training-scales-info/ear-training-scales-info.component";
import { StaffBuildingChordsInfoComponent } from "../information/staff-building/staff-building-chords-info/staff-building-chords-info.component";
import { StaffBuildingIntervalsInfoComponent } from "../information/staff-building/staff-building-intervals-info/staff-building-intervals-info.component";
import { StaffBuildingScalesInfoComponent } from "../information/staff-building/staff-building-scales-info/staff-building-scales-info.component";
import { StaffIdentificationIntervalsInfoComponent } from "../information/staff-identification/staff-identification-intervals-info/staff-identification-intervals-info.component";
import { StaffIdentificationNotesInfoComponent } from "../information/staff-identification/staff-identification-notes-info/staff-identification-notes-info.component";
import { StaffIdentificationScalesInfoComponent } from "../information/staff-identification/staff-identification-scales-info/staff-identification-scales-info.component";
import { Information } from "../information/information";

//Utility class that handles routing and redirection
export class RoutingUtil{
    //Navigates to appropriate menu endpoint depending on game id
    public static navigateToMenu(gameId: number, router: Router){
        switch(gameId){
            case GameId.EarTraining_Chords:
                router.navigate([RouteConstants.CHORD_EAR_TRAINING_MENU]);
                break;
            case GameId.EarTraining_Intervals:
                router.navigate([RouteConstants.INTERVAL_EAR_TRAINING_MENU]);
                break;
            case GameId.EarTraining_Scales:
                router.navigate([RouteConstants.SCALE_EAR_TRAINING_MENU]);
                break;
            case GameId.StaffIdentification_Chords:
                router.navigate([RouteConstants.CHORD_STAFF_IDENTIFICATION_MENU]);
                break;
            case GameId.StaffIdentification_Scales:
                router.navigate([RouteConstants.SCALE_STAFF_IDENTIFICATION_MENU]);
                break;
            case GameId.StaffIdentification_Intervals:
                router.navigate([RouteConstants.INTERVAL_STAFF_IDENTIFICATION_MENU]);
                break;
            case GameId.StaffIdentification_Notes:
                router.navigate([RouteConstants.NOTE_STAFF_IDENTIFICATION_MENU]);
                break;
            case GameId.StaffBuilding_Chords:
                router.navigate([RouteConstants.CHORD_STAFF_BUILDING_MENU]);
                break;
            case GameId.StaffBuilding_Scales:
                router.navigate([RouteConstants.SCALE_STAFF_BUILDING_MENU]);
                break;
            case GameId.StaffBuilding_Intervals:
                router.navigate([RouteConstants.INTERVAL_STAFF_BUILDING_MENU]);
                break;

            default:
                router.navigate(['/']);
                break;
        }
    }

    //Navigates to appropriate game endpoint depending on game id
    public static navigateToGame(gameId: number, router: Router){
        switch(gameId){
            case GameId.EarTraining_Chords:
                router.navigate([RouteConstants.CHORD_EAR_TRAINING_GAME]);
                break;
            case GameId.EarTraining_Intervals:
                router.navigate([RouteConstants.INTERVAL_EAR_TRAINING_GAME]);
                break;
            case GameId.EarTraining_Scales:
                router.navigate([RouteConstants.SCALE_EAR_TRAINING_GAME]);
                break;
            case GameId.StaffIdentification_Chords:
                router.navigate([RouteConstants.CHORD_STAFF_IDENTIFICATION_GAME]);
                break;
            case GameId.StaffIdentification_Scales:
                router.navigate([RouteConstants.SCALE_STAFF_IDENTIFICATION_GAME]);
                break;
            case GameId.StaffIdentification_Intervals:
                router.navigate([RouteConstants.INTERVAL_STAFF_IDENTIFICATION_GAME]);
                break;
            case GameId.StaffIdentification_Notes:
                router.navigate([RouteConstants.NOTE_STAFF_IDENTIFICATION_GAME]);
                break;
            case GameId.StaffBuilding_Chords:
                router.navigate([RouteConstants.CHORD_STAFF_BUILDING_GAME]);
                break;
            case GameId.StaffBuilding_Scales:
                router.navigate([RouteConstants.SCALE_STAFF_BUILDING_GAME]);
                break;
            case GameId.StaffBuilding_Intervals:
                router.navigate([RouteConstants.INTERVAL_STAFF_BUILDING_GAME]);
                break;
            default:
                router.navigate(['/']);
                break;
        }
    }

    //Create a dialog component for the specified information page
    public static openInfoDialog(gameId: number, dialog: MatDialog){
        const payload = {
            gameId: gameId
        };

        //Select appropriate component depending on game ID
        // let component: Information;
        // switch (gameId) {
        //     case GameId.EarTraining_Chords:
        //         component = new EarTrainingChordsInfoComponent(payload, dialog);
        //         break;
        //     case GameId.EarTraining_Intervals:
        //         component = new EarTrainingIntervalsInfoComponent(payload, dialog);
        //         break;
        //     case GameId.EarTraining_Scales:
        //         component = new EarTrainingScalesInfoComponent(payload, dialog);
        //         break;
        //     case GameId.StaffIdentification_Chords:
        //         component = new StaffIdentificationChordsInfoComponent(payload, dialog);
        //         break;
        //     case GameId.StaffIdentification_Scales:
        //         component = new StaffIdentificationScalesInfoComponent(payload, dialog);
        //         break;
        //     case GameId.StaffIdentification_Intervals:
        //         component = new StaffIdentificationIntervalsInfoComponent(payload, dialog);
        //         break;
        //     case GameId.StaffIdentification_Notes:
        //         component = new StaffIdentificationNotesInfoComponent(payload, dialog);
        //         break;
        //     case GameId.StaffBuilding_Chords:
        //         component = new StaffBuildingChordsInfoComponent(payload, dialog);
        //         break;
        //     case GameId.StaffBuilding_Scales:
        //         component = new StaffBuildingScalesInfoComponent(payload, dialog);
        //         break;
        //     case GameId.StaffBuilding_Intervals:
        //         component = new StaffBuildingIntervalsInfoComponent(payload, dialog);
        //         break;
        //     default:
        //         console.error("invalid game id");
        //         return;
        // }

        // if(component !== undefined && component !== null){
        //     component.open();
        // }

        // Select appropriate component depending on game ID
        let component: any;
        switch (gameId) {
            case GameId.EarTraining_Chords:
                component = EarTrainingChordsInfoComponent;
                break;
            case GameId.EarTraining_Intervals:
                component = EarTrainingIntervalsInfoComponent;
                break;
            case GameId.EarTraining_Scales:
                component = EarTrainingScalesInfoComponent;
                break;
            case GameId.StaffIdentification_Chords:
                component = StaffIdentificationChordsInfoComponent;
                break;
            case GameId.StaffIdentification_Scales:
                component = StaffIdentificationScalesInfoComponent;
                break;
            case GameId.StaffIdentification_Intervals:
                component = StaffIdentificationIntervalsInfoComponent;
                break;
            case GameId.StaffIdentification_Notes:
                component = StaffIdentificationNotesInfoComponent;
                break;
            case GameId.StaffBuilding_Chords:
                component = StaffBuildingChordsInfoComponent;
                break;
            case GameId.StaffBuilding_Scales:
                component = StaffBuildingScalesInfoComponent;
                break;
            case GameId.StaffBuilding_Intervals:
                component = StaffBuildingIntervalsInfoComponent;
                break;
            default:
                console.error("invalid game id");
                return;
        }

        // Open the dialog using MatDialog.open() to ensure proper lifecycle management
        const dialogRef = dialog.open(component, {
            data: payload,
            panelClass: 'information',  // Optional, adjust as needed
            width: '800px',
            maxWidth: '95vw',
            maxHeight: '80vh',
        });

        if (component && dialogRef) {
            component.dialogRef = dialogRef;
        }

    }
}