import { Clef } from "../../constants/DifficultyEnum";
import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule} from '@angular/common';
import { SharedDataService } from '../../util/SharedDataService';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { GameHandler } from "../GameHandler";
import { ScoreRow } from "../score-sheet/ScoreTypes";
import { MatDialog } from '@angular/material/dialog';
import { RoutingUtil } from "../../util/RoutingUtil";
import { StaffBuilder } from "../../staff-graphics/StaffBuilder";
import { StaffNote } from "../../staff-graphics/models/StaffNote";
import { Note } from "../../models/Note";
import { CanvasDoubleBuffer } from "../../staff-graphics/CanvasDoubleBuffer";
import { StaffInterval } from "../../staff-graphics/models/StaffInterval";
import { StaffChord } from "../../staff-graphics/models/StaffChord";
import { StaffScale } from "../../staff-graphics/models/StaffScale";
import { StaffElement } from "../../staff-graphics/models/StaffElement";
import { Interval } from "../../models/Interval";
import { Chord } from "../../models/Chord";
import { Scale } from "../../models/Scale";
import { CommonUtil } from "../../util/CommonUtil";
import { GameId } from "../../constants/GameIdEnum";


@Component({
  selector: 'app-staff-game-handler',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './staff-game-handler.component.html',
  styleUrl: './staff-game-handler.component.css'
})

export class StaffGameHandlerComponent extends GameHandler{

    //References to the canvas element for two-way data binding purposes
    @ViewChild('staffCanvas')
    canvas!: ElementRef<HTMLCanvasElement>;
    canvasDoubleBuffer!: CanvasDoubleBuffer;

    @ViewChild('buttonCanvas')
    btnCanvas!: ElementRef<HTMLCanvasElement>

    @ViewChild('staffContainer')
    parentDivRef!: ElementRef<HTMLDivElement>;

    @ViewChild('controlBtn_doubleSharp')
    doubleSharpBtnRef!: ElementRef<HTMLButtonElement>;

    //Placeholder parent element
    protected randomElement!: StaffElement; 
    protected builder!: StaffBuilder;

    protected isTrebleClef: boolean = true;
    protected clickEnabled: boolean = true; //Enables click events for staff building games
    protected hoverEnabled: boolean = true; //Enables hover events for staff building games
    //Track mouse y-axis position
    private mouseY: number = 0;
    private yPos: number = 0;
    private noteCount: number = 0;

    protected images = [];

    //Staff building game buttons
    protected isNaturalSelected: boolean = true;
    protected isFlatSelected: boolean = false;
    protected isSharpSelected: boolean = false;
    protected isDoubleFlatSelected: boolean = false;
    protected isDoubleSharpSelected: boolean = false;

    //Staff building game constants
    private readonly NATURAL_ID = 'nat-button';
    private readonly SHARP_ID = 'sharp-button';
    private readonly FLAT_ID = 'flat-button';
    private readonly DOUBLESHARP_ID = 'ds-button';
    private readonly DOUBLEFLAT_ID = 'df-button';
    private readonly REMOVE_ID = 'remove-button';
    private readonly CLEAR_ID = 'clear-button';
    private lastId: string = '';

    //For displaying keyboard controls
    public isKeyboardControlsHidden: boolean = false;

    //Store user operating system for applying specific styles
    // protected readonly OS: string = CommonUtil.getOS();


    constructor(protected override router: Router, protected override sharedDataService: SharedDataService, protected override dialog: MatDialog,
                protected override route: ActivatedRoute
    ) {
        super(router, sharedDataService, dialog, route);
    }

    ngOnInit(): void {
        this.initModule();
    }

    override initModule(): boolean{
        //Receives payload from difficulty selector
        const payload = this.sharedDataService.getAndClearFormData();
        if (payload) {
            this.difficulty = payload['difficulty'];
            this.clefType = CommonUtil.flipCoin() === true ? Clef.Treble : Clef.Bass;
            this.practiceMode = payload['practiceModeSelected'];
            this.challengeMode = payload['challengeModeSelected'];
        } else {
            RoutingUtil.navigateToMenu(this.gameId, this.router);
            return false;
        }

        this.isTrebleClef = CommonUtil.flipCoin();
        this.initializeTimers();

        const pixelRatio: number = window.devicePixelRatio || 1;
        if(pixelRatio > 1) this.isKeyboardControlsHidden = true;
        return true;
    }

    public submitNote(): void{}
    
    protected override playSample(correct: boolean): void {}

    //Ends current game and displays score sheet
    protected override finish(): void {
        const payload = {
            scoreRows: this.scoreRows,
            score: this.score,
            gameId: this.gameId,
            difficulty: this.difficulty,
            time: this.secondsPassed,
            mode: this.challengeMode ? true : false
        }
        this.stopTimer();

        //Opens score sheet dialog component
        this.handleDialog(payload);
    }

    protected check(guess: number | string, buttonRef: HTMLButtonElement): void{}
    
    //Handles logic for checking if a user submitted a correct answer
    protected override checkAnswer(guess: number | string, buttonRef: HTMLButtonElement){
        let answer: number | string = "";
        if(this.randomElement instanceof StaffNote){
            answer = this.randomElement.getNote.getRoot;
        } else if(this.randomElement instanceof StaffInterval || this.randomElement instanceof StaffChord){
            answer = this.randomElement.getQuality;
        } else if(this.randomElement instanceof StaffScale){
            answer = this.randomElement.getScale.getQuality;
        }

        let backgroundClassColor = '';
        const id: string = buttonRef.id;

        if(guess === answer){
            this.flashButton(id, true);
            backgroundClassColor = this.changeButtonBackgroundColor(true);
            this.correctGuesses += 1;
            this.incrementScore();

            //Rebuild the staff
            this.refreshStaff(this.randomElement);
        } else {
            this.flashButton(id, false);
            this.decrementScore();
            backgroundClassColor = this.changeButtonBackgroundColor(false);
        }
        buttonRef.classList.add(backgroundClassColor);
        this.totalGuesses += 1;


        //Removes flash class from affected button
        setTimeout(() => {
            buttonRef.classList.remove(backgroundClassColor);
            this.resetFlashClasses();
        }, 200)

        //Adds score row to array for results dialog
        const scoreRow: ScoreRow = {
            id: guess,
            expectedId: answer,
            gameId: this.gameId 
        }
        this.scoreRows.push(scoreRow);
    }
    
    //Refreshes the staff element and resets builder note array
    protected refreshStaff(element: StaffElement): void {
        let staffElement: StaffElement = new StaffNote(new Note("C4"), this.canvas.nativeElement);
        this.isTrebleClef = CommonUtil.flipCoin();
        if(element instanceof StaffNote){
            const note: Note = this.generateRandomNote(this.isTrebleClef);
            staffElement = new StaffNote(note, this.canvas.nativeElement);
        } else if(element instanceof StaffInterval){
            const interval: Interval = this.generateRandomInterval(this.isTrebleClef);
            staffElement = new StaffInterval(interval, this.canvas.nativeElement);
        } else if(element instanceof StaffChord){
            const chord: Chord = this.generateRandomChord(this.isTrebleClef);
            staffElement = new StaffChord(chord, this.canvas.nativeElement);
        } else if(element instanceof StaffScale){
            const scale: Scale = this.generateRandomScale(this.isTrebleClef);
            staffElement = new StaffScale(scale, this.canvas.nativeElement);
        }
        this.randomElement = staffElement
        this.builder.resetNoteArray(this.randomElement, this.isTrebleClef, this.mouseY);
    };

    //Sets fundamental game variables back to 0
    protected override restartGame(): void{
        this.correctGuesses = 0;
        this.totalGuesses = 0;
        this.timer$ = new Observable<string>();
        this.loading$ = new Observable<number>();
        this.secondsPassed = 0;
        this.countdownSubscription = new Subscription();
        this.scoreRows = [];
        this.score = 0;
        this.initializeTimers();

        this.refreshStaff(this.randomElement);
    }

    //Sets the size of the canvas to match the size of its parent div
    protected setCanvasSize(): void {
        const canvas: HTMLCanvasElement = this.canvas.nativeElement;       
        const pixelRatio: number = window.devicePixelRatio || 1;
        const isMobile: boolean = pixelRatio > 1 ? true : false;
        if(this.gameId == GameId.StaffIdentification_Scales || this.gameId == GameId.StaffBuilding_Scales){
            canvas.width = isMobile ? 1200 * pixelRatio : 1050 * pixelRatio;
            canvas.height =  isMobile ? 650 * pixelRatio : 550 * pixelRatio; 
        } else{ 
            canvas.width = isMobile ? 850 * pixelRatio : 850 * pixelRatio;
            canvas.height =  isMobile ? 750 * pixelRatio : 500 * pixelRatio;
            
        }
        this.canvas.nativeElement = canvas;
    }

    //Manages the event handlers for the staff building games
    protected enableEventHandlers(): void{
        //Update pitch count 
        this.yPos = this.getYPos();
        this.noteCount += 1;
        this.canvas.nativeElement.addEventListener('click', (event) => {
            this.handleClickEvent(event);
        });
        this.canvas.nativeElement.addEventListener('touchend', (event) => {
            this.handleClickEvent(event);
        });

        this.canvas.nativeElement.addEventListener('touchmove', (event) =>{
            event.preventDefault();
        });

    
        //Mousemove event
        this.canvas.nativeElement.addEventListener('mousemove', (event) => {
            this.yPos = this.getYPos();
            const mouseY: number = event.clientY - this.canvas.nativeElement.getBoundingClientRect().top;   
            this.mouseY = mouseY;

            if(this.clickEnabled){
                const mouseY = event.clientY - this.canvas.nativeElement.getBoundingClientRect().top;    
                this.mouseY = mouseY;

                requestAnimationFrame(() => {
                    this.builder.setHoverEventListener(this.canvas.nativeElement, this.isTrebleClef, this.randomElement, event, mouseY);
                });
            }
        });

        this.canvas.nativeElement.addEventListener('touchmove', (event) => {
            this.yPos = this.getYPos();
            const mouseY: number = event.touches[0].clientY - this.canvas.nativeElement.getBoundingClientRect().top;   
            this.mouseY = mouseY;

            if(this.clickEnabled){
                const mouseY = event.touches[0].clientY - this.canvas.nativeElement.getBoundingClientRect().top;    
                this.mouseY = mouseY;

                requestAnimationFrame(() => {
                    this.builder.setHoverEventListener(this.canvas.nativeElement, this.isTrebleClef, this.randomElement, event, mouseY);
                });
            }
        })

        //Keypress event
        document.addEventListener("keyup", (event) => {
            if(this.clickEnabled){
                if (event.key === "w" || event.key === "W" ||
                event.key === 's' || event.key === 'S' ||
                event.key === 'a' || event.key === 'A' ||
                event.key === 'd' || event.key === 'D' ||
                event.key === 'q' || event.key === 'Q' ||
                event.key === 'x' || event.key === 'X' || 
                event.key === 'c' || event.key === 'C') {
                    this.builder.setHoverEventListener(this.canvas.nativeElement, this.isTrebleClef, this.randomElement, event, this.mouseY);

                    //Update button colors for event key switches
                    let id: string = this.lastId;
                    if(event.key === 'w' || event.key === 'W'){
                        id=this.SHARP_ID;
                    } else if(event.key === 's' || event.key === 'S'){
                        id=this.DOUBLESHARP_ID;
                    } else if(event.key === 'a' || event.key === 'A'){
                        id=this.FLAT_ID;
                    } else if(event.key === 'd' || event.key === 'D'){
                        id=this.DOUBLEFLAT_ID;
                    } else if(event.key === 'q' || event.key === 'Q'){
                        id=this.NATURAL_ID;
                    } else {
                        id = '';
                    }

                    //Only call method if the id is not empty
                    if(!CommonUtil.isEmpty(id)){
                        this.lastId = id;
                        this.selectStaffControlElement(id);
                    }

                }
            }
        });
    }

    //Returns the Y position of the first note of a staff element object
    private getYPos(): number{
        if(this.randomElement instanceof StaffNote){
            return this.randomElement.getYPos;
        } else if(this.randomElement instanceof StaffInterval){
            return this.randomElement.getNote1.getYPos;
        } else if(this.randomElement instanceof StaffChord || this.randomElement instanceof StaffScale){
            return this.randomElement.getNotes[0].getYPos;
        }

        //Return 0 on type mismatch
        return 0;
    }

    //Destroys subscription object when component is terminated
    ngOnDestroy() {
        this.stopTimer();
    }

    //Return the name of the staff element, or an empty string if nothing is defined
    public getElementName(): string{
        if(this.randomElement == null || this.randomElement == undefined) return ""
        else if(this.randomElement.getName == null || this.randomElement.getName == undefined) return "";
        return this.randomElement.getName;
    }

    //Handles the logic for setting a note in the event of a click or touch
    private handleClickEvent(event: MouseEvent | TouchEvent): void{
        if(this.clickEnabled){
            requestAnimationFrame(async () => {
                let mouseY: number = 0;
                if(event instanceof MouseEvent){
                    mouseY = event.clientY - this.canvas.nativeElement.getBoundingClientRect().top;    
                } else if(event instanceof TouchEvent){
                    mouseY = event.changedTouches[0].clientY - this.canvas.nativeElement.getBoundingClientRect().top;    
                }

                const aspectRatio: number = this.canvas.nativeElement.height / this.canvas.nativeElement.width;
                if(mouseY >= 15 && (mouseY * aspectRatio) < this.yPos){
                    this.builder.setClickEventListener(this.isTrebleClef, this.randomElement, mouseY);
                    let guess: number = 0;
                    let answer: number = -1;
                    const guessedValue: StaffElement = this.builder.evaluateNoteSelection(this.gameId, this.isTrebleClef);

                    //If note returns as A0, simply return from the event handler
                    if(guessedValue.getName === "A0"){
                        return;
                    }

                    //Get the guess and answer value depending on staff element type
                    if(guessedValue instanceof StaffInterval &&
                              this.randomElement instanceof StaffInterval){
                        //Ensure that the second note is relevant to the scale
                        this.clickEnabled = false;
                        guess = guessedValue.getQuality.valueOf();
                        answer = this.randomElement.getQuality.valueOf();
                        
                        if(guessedValue.getNote2.getFullName === this.randomElement.getNote2.getFullName){
                           answer = guess;
                        } else{
                            guess = -1;
                        }
                    } else if(guessedValue instanceof StaffChord &&
                                this.randomElement instanceof StaffChord){
                        //Only continue if the two lengths match
                        this.yPos = this.getYPos();
                        if(this.randomElement.getNotes.length != guessedValue.getNotes.length){
                            return;
                        }
                        this.clickEnabled = false;
                        guess = guessedValue.getQuality.valueOf();
                        answer = this.randomElement.getQuality.valueOf();

                    } else if(guessedValue instanceof StaffScale && 
                            this.randomElement instanceof StaffScale){
                        this.noteCount = this.builder.noteCount;
                        if(this.noteCount < this.randomElement.getNotes.length){
                            return;
                        }
                        this.clickEnabled = false;
                        guess = guessedValue.getScale.getQuality.valueOf();
                        answer = this.randomElement.getScale.getQuality.valueOf();

                        //Adjust Ionian and Aeolian values to match major and minor scales
                        if(this.gameId == GameId.StaffIdentification_Scales ||
                            (this.gameId == GameId.StaffBuilding_Scales && this.difficulty == 2)){
                            if(guess === 1){
                                guess = 17;
                            } else if(guess === 2){
                                guess = 18;
                            }
                        }
                        
                    } 

                    if(guess === answer){
                        //Answer is correct, update the score 
                        this.correctGuesses += 1;
                        this.incrementScore();
                        
                        //Flash green to indicate that the answer was correct
                        this.builder.changeNoteColor(guessedValue, this.isTrebleClef, true);

                        //Generate new staff element and refresh the canvas
                        setTimeout(async () => {
                            if(this.randomElement instanceof StaffNote){
                        
                            } else if(this.randomElement instanceof StaffInterval){
                                const interval = this.generateRandomInterval(this.isTrebleClef);
                                this.randomElement = new StaffInterval(interval, this.canvas.nativeElement);
                            } else if(this.randomElement instanceof StaffChord){
                                const chord = this.generateRandomChord(this.isTrebleClef);
                                this.randomElement = new StaffChord(chord, this.canvas.nativeElement);
                            } else if(this.randomElement instanceof StaffScale){
                                const scale = this.generateRandomScale(this.isTrebleClef);
                                this.randomElement = new StaffScale(scale, this.canvas.nativeElement);
                            } 
                            
                            this.clickEnabled = await this.builder.resetNoteArray(this.randomElement, this.isTrebleClef, this.mouseY);
                        }, 500);
                        this.isTrebleClef = CommonUtil.flipCoin();
                    } else{
                        //Answer is not correct, update the score
                        this.decrementScore();

                        //Flash red to indicate that the answer was NOT correct
                        this.builder.changeNoteColor(guessedValue, this.isTrebleClef, false);
                        setTimeout(async () => {
                            this.clickEnabled = await this.builder.resetNoteArray(this.randomElement, this.isTrebleClef, this.mouseY);
                        }, 500);                                
                    }
                    // this.builder.pitchCount += 1;
                    this.totalGuesses += 1;
                    

                    //Update score row
                    const scoreRow: ScoreRow = {
                        id: guess,
                        expectedId: answer,
                        gameId: this.gameId 
                    }
                    this.scoreRows.push(scoreRow);
                    setTimeout(() =>{
                        this.selectStaffControlElement(this.NATURAL_ID);
                    }, 500);

                }


            });

            
        }        
    }

    //Handle clicking a staff builder control button
    public selectStaffControlElement(id: string): void{
        switch(id){
            case this.NATURAL_ID:
                this.isNaturalSelected = true;
                this.isDoubleFlatSelected = false;
                this.isDoubleSharpSelected = false;
                this.isSharpSelected = false;
                this.isFlatSelected = false;
                this.builder.currPitch = 0;
                break;
            case this.SHARP_ID:
                this.isNaturalSelected = false;
                this.isDoubleFlatSelected = false;
                this.isDoubleSharpSelected = false;
                this.isSharpSelected = true;
                this.isFlatSelected = false;
                this.builder.currPitch = 1;
                break;
            case this.FLAT_ID:
                this.isNaturalSelected = false;
                this.isDoubleFlatSelected = false;
                this.isDoubleSharpSelected = false;
                this.isSharpSelected = false;
                this.isFlatSelected = true;
                this.builder.currPitch = -1;
                break;
            case this.DOUBLESHARP_ID:
                this.isNaturalSelected = false;
                this.isDoubleFlatSelected = false;
                this.isDoubleSharpSelected = true;
                this.isSharpSelected = false;
                this.isFlatSelected = false;
                this.builder.currPitch = 2;
                break;
            case this.DOUBLEFLAT_ID:
                this.isNaturalSelected = false;
                this.isDoubleFlatSelected = true;
                this.isDoubleSharpSelected = false;
                this.isSharpSelected = false;
                this.isFlatSelected = false;
                this.builder.currPitch = -2;
                break;
            case this.REMOVE_ID:
                this.builder.removeLastNote();
                const ctx: CanvasRenderingContext2D | null = this.canvas.nativeElement.getContext('2d');
                if(ctx !== null){
                    this.builder.drawCoreStaffElements(ctx, this.isTrebleClef);
                }
                break;
            case this.CLEAR_ID: 
                this.builder.removeAllNotes();
                const ctx2: CanvasRenderingContext2D | null = this.canvas.nativeElement.getContext('2d');
                if(ctx2 !== null){
                    this.builder.drawCoreStaffElements(ctx2, this.isTrebleClef);
                }

                //Recall method with nat symbol
                this.selectStaffControlElement(this.NATURAL_ID);
                break;
            default:
                break;
        }

    }

}
