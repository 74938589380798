import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HeaderInfoComponent } from "../../subcomponents/header-info/header-info.component";
import { ChordDifficultyInfoComponent } from "../../subcomponents/difficulty/chord-difficulty-info/chord-difficulty-info.component";
import { TimerInfoComponent } from "../../subcomponents/timer-info/timer-info.component";
import { ScoreInfoComponent } from "../../subcomponents/score-info/score-info.component";
import { ClefInfoComponent } from "../../subcomponents/clef-info/clef-info.component";
import { Information } from '../../information';
import { StaffIdentificationIntervalsInfoComponent } from '../staff-identification-intervals-info/staff-identification-intervals-info.component';

@Component({
  selector: 'app-staff-identification-chords-info', 
  standalone: true,
  imports: [HeaderInfoComponent, ChordDifficultyInfoComponent, TimerInfoComponent, ScoreInfoComponent, ClefInfoComponent],
  templateUrl: './staff-identification-chords-info.component.html',
  styleUrl: './../../information.component.css'
})
export class StaffIdentificationChordsInfoComponent extends Information{
    constructor(@Inject(MAT_DIALOG_DATA) payload: any, dialog: MatDialog){
        super(payload, dialog);
        this.child = StaffIdentificationChordsInfoComponent;
        
    }
}
