import { Component, ElementRef, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {} from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from "../../../tooltip/tooltip.component";
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../util/SharedDataService';
import { DifficultySelectorComponent } from '../../difficulty-selector.component';
import { GameId } from '../../../constants/GameIdEnum';
import { AuthenticationService } from '../../../auth/service/AuthenticationService';
import { SpinnerComponent } from '../../../graphics/spinner/spinner.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-note-staff-identification-difficulty-selector',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatTooltipModule, SpinnerComponent],
  templateUrl: '../../difficulty-selector.component.html',
  styleUrl: '../../difficulty-selector.component.css'
})
export class NoteStaffIdentificationDifficultySelectorComponent extends DifficultySelectorComponent {
    constructor(protected override router: Router, protected override sharedDataService: SharedDataService, protected override el: ElementRef, 
        protected override authService: AuthenticationService, @Inject(PLATFORM_ID) protected override platformId: Object, protected override renderer: Renderer2,
        protected override dialog: MatDialog){
        super(router, sharedDataService, el, authService, platformId, renderer, dialog);
        this.gameId = GameId.StaffIdentification_Notes;
        this.title = "Staff Identification";
        this.subtitle = "Identify Notes";
        this.description = "Identify the note based on the provided notation";
        this.description_practiceMode = "No time limit, practice identifying notes for as long as you want.";
        this.description_challengeMode = "Race against the clock to get as many answers correct before time runs out.";
        this.easyButtonTooltip = "C Major Notes Only";
        this.mediumButtonTootip = "";
        this.hardButtonTooltip = "All Notes";

        if(this.authService.getDifficultySelection() === "2"){
            this.authService.setDifficultySelection("1");
            this.difficultyForm.get("difficulty")?.setValue(this.authService.getDifficultySelection());
        }
    }
}
