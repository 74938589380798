<div #infoContainer class="info-container">
    <app-header-info></app-header-info>
    <p>When the game starts, a random scale will be generated and played back to your audio output.  The notes will be played
        in sequential order.  Based on the notes that you hear, select the most appropriate quality from the provided options.
        The scale will always be ascending.
        To replay the audio sample, press the speaker icon in the top left of the game window.  
        When you answer correctly, a new scale will be generated and played back to your audio output.
        The scale will not change until you answer correctly, no matter how many incorrect responses are submitted.
    </p>
    <div class="gif-image-container">
        <img src="assets/information/GIFs/ET_Scales.gif">
    </div>

    <app-scale-difficulty-info></app-scale-difficulty-info>
    <app-timer-info></app-timer-info>
    <app-score-info></app-score-info>
    <div class="auth-control-container">
        <a (click)="close()">Close</a>
    </div>

</div>
