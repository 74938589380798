<h2>Controls: </h2>
<p>You can place a note by hovering over the staff and clicking on a highlighted note (mouse and keyboard) or by
    touching and dragging your finger (mobile and tablet).  If a note is highlighted red, it cannot be placed on the staff, so 
    you must ensure that the note is highlighted grey before placing.  </p>

<p>To select a pitch modifier, you can either select the appropriate button below
    the staff or use the keyboard controls listed below the buttons.</p>

<div class="gif-image-container">
    <img src="assets/information/SB_Controls.png">
</div>


<p>To remove notes, you can either press the back arrow button to remove a single note, </p>
<div class="gif-image-container">
    <img src="assets/information/GIFs/SB_RemoveNote.gif">
</div>

<p>or press the X button to clear all of the selected notes.</p>
<div class="gif-image-container">
    <img src="assets/information/GIFs/SB_ClearNotes.gif">
</div>
<p>You can also use the 'X' and 'C' keys on a keyboard to perform these actions respectively.  </p>
