<div #infoContainer class="info-container">
    <app-header-info></app-header-info>
    <p>When the game starts, a random chord quality will be generated at the top of the screen, and the first note of the 
        chord will be drawn.  Given the root note and the chord quality, build out the rest of the chord.  
        The chord will always be in root position.  
        When you answer correctly, a new chord quality will be generated and drawn to the screen.
        The chord will not change until you answer correctly, no matter how many incorrect responses are submitted.
    </p>


    <div class="gif-image-container">
        <img src="assets/information/GIFs/SB_Chords.gif">
    </div>
    <app-controls-info></app-controls-info>
    <app-chord-difficulty-info></app-chord-difficulty-info>
    <app-timer-info></app-timer-info>
    <app-score-info></app-score-info>
    <app-clef-info></app-clef-info>
    <div class="auth-control-container">
        <a (click)="close()">Close</a>
    </div>

</div>
