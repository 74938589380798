import { Component } from '@angular/core';

@Component({
  selector: 'app-chord-difficulty-info',
  standalone: true,
  imports: [],
  templateUrl: './chord-difficulty-info.component.html',
  styleUrl: './chord-difficulty-info.component.css'
})
export class ChordDifficultyInfoComponent {

}
