<div #infoContainer class="info-container">
    <app-header-info></app-header-info>
    <p>When the game starts, a random interval quality will be generated at the top of the screen, and a random note will be drawn.  
        Given the random note, select the correct note to match the interval quality.  
        The interval will always be ascending.  
        When you answer correctly, a new interval quality will be generated and drawn to the screen.
        The interval will not change until you answer correctly, no matter how many incorrect responses are submitted.
    </p>


    <div class="gif-image-container">
        <img src="assets/information/GIFs/SB_Intervals.gif">
    </div>
    <app-controls-info></app-controls-info>
    <app-interval-difficulty-info></app-interval-difficulty-info>
    <app-timer-info></app-timer-info>
    <app-score-info></app-score-info>
    <app-clef-info></app-clef-info>
    <div class="auth-control-container">
        <a (click)="close()">Close</a>
    </div>

</div>
