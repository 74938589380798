import { Component } from '@angular/core';

@Component({
  selector: 'app-interval-difficulty-info',
  standalone: true,
  imports: [],
  templateUrl: './interval-difficulty-info.component.html',
  styleUrl: './interval-difficulty-info.component.css'
})
export class IntervalDifficultyInfoComponent {

}
