import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Information } from '../../information';
import { ClefInfoComponent } from '../../subcomponents/clef-info/clef-info.component';
import { ChordDifficultyInfoComponent } from '../../subcomponents/difficulty/chord-difficulty-info/chord-difficulty-info.component';
import { HeaderInfoComponent } from '../../subcomponents/header-info/header-info.component';
import { ScoreInfoComponent } from '../../subcomponents/score-info/score-info.component';
import { TimerInfoComponent } from '../../subcomponents/timer-info/timer-info.component';

@Component({
  selector: 'app-ear-training-chords-info',
  standalone: true,
  imports: [HeaderInfoComponent, ChordDifficultyInfoComponent, TimerInfoComponent, ScoreInfoComponent],
  templateUrl: './ear-training-chords-info.component.html',
  styleUrl: './../../information.component.css'
})
export class EarTrainingChordsInfoComponent extends Information{
    constructor(@Inject(MAT_DIALOG_DATA) payload: any, dialog: MatDialog){
        super(payload, dialog);
        this.child = EarTrainingChordsInfoComponent;
        
    }

}
