import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Information } from '../../information';
import { HeaderInfoComponent } from '../../subcomponents/header-info/header-info.component';
import { ScoreInfoComponent } from '../../subcomponents/score-info/score-info.component';
import { TimerInfoComponent } from '../../subcomponents/timer-info/timer-info.component';
import { ScaleDifficultyInfoComponent } from "../../subcomponents/difficulty/scale-difficulty-info/scale-difficulty-info.component";

@Component({
  selector: 'app-ear-training-scales-info',
  standalone: true,
  imports: [HeaderInfoComponent, TimerInfoComponent, ScoreInfoComponent, ScaleDifficultyInfoComponent],
  templateUrl: './ear-training-scales-info.component.html',
  styleUrl: './../../information.component.css'
})
export class EarTrainingScalesInfoComponent extends Information{
    constructor(@Inject(MAT_DIALOG_DATA) payload: any, dialog: MatDialog){
        super(payload, dialog);
        this.child = EarTrainingScalesInfoComponent;
        
    }

}
