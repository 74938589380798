<div class="app-parent-container">
    <div class="main-container margin-top">
        <div class="unavailable-container margin-top">
            <div class="ascii-art">
                <div></div>
                <pre style="font-family: monospace;"><b>
        xx      xx      xx      xx        
         xx    xx        xx    xx
          xx  xx          xx  xx
           xx              xx
          xx  xx          xx  xx
         xx    xx        xx    xx
        xx      xx      xx      xx
        
                   xxxx
                xxx    xxx
             xxx          xxx
           xx                xx
         xx                    xx
        x                        x
                </b>
                </pre>
                <div></div>
            </div>
            
            
            <p class="auth-error-message"><b>Our backend servers are currently unavailable.  We apologize for 
                this inconvenience.</b>
            </p>
        </div>
    </div>
</div>
