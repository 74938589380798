import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";

@Component({
    selector: 'app-information-module',
    standalone: true,
    imports: [MatDialogModule, MatButtonModule],
    template: `<ng-container #container></ng-container>`,
    styleUrl: './information.component.css'
})
export class Information{
    protected payload: any;
    protected dialog: MatDialog;
    protected child!: typeof Information;
    protected dialogRef!: MatDialogRef<Information>;
    constructor(@Inject(MAT_DIALOG_DATA) payload: any, dialog: MatDialog){
        this.payload = payload;
        this.dialog = dialog;        
    }

    //Opens the dialog of the specified child class type
    public open(): void{
        this.dialogRef = this.dialog.open(this.child, {
            panelClass: 'information',
            width: '800px',
            maxWidth: '95vw',
            maxHeight: '80vh',
            data: this.payload
        });
    }

    //Closes all open dialogs
    protected close(): void{
        this.dialog.closeAll();
    }
}