import { Component } from '@angular/core';

@Component({
  selector: 'app-note-difficulty-info',
  standalone: true,
  imports: [],
  templateUrl: './note-difficulty-info.component.html',
  styleUrl: './note-difficulty-info.component.css'
})
export class NoteDifficultyInfoComponent {

}
