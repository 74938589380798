<div class="app-parent-container">
    <div class="main-container">
        <mat-dialog-content>
            <div>
                <h3 class="dialog-header">User ID: {{user.id}} ----- Username: {{user.username}}</h3>
                <div>Always verify that you are updating the correct user.  You
                    should always double check the id, username, and email of a user before making any changes.
                </div>
            </div>
            
            <!-- Update username -->
            <div class="admin-dialog-field-row">
                <p class="dialog-header dialog-header-left">Update Username: </p>
                <input type="text" [defaultValue]="user.username" [(ngModel)]="newUsername">
                <div></div>
                <button (click)="updateUsername()">Submit</button>
            </div>
            <div *ngIf="errorFlag_usernameIsInvalid" class="auth-error-message auth-error-message-left">Username must be between 6 and 16 alphanumeric characters</div>
            <div *ngIf="errorFlag_usernameIsTaken" class="auth-error-message">That username is already taken</div>
            <div *ngIf="errorFlag_usernameFailedToUpdate" class="auth-error-message">The username failed to update</div>
            <div *ngIf="errorFlag_usernameServiceUnavailable" class="auth-error-message">The service is currently unavailable</div>
            <div *ngIf="successFlag_username" class="auth-success-message">Username was successfully updated</div>
            
            <!-- Update password -->
            <div class="admin-dialog-field-row">
                <p class="dialog-header dialog-header-left">Change Password: </p>
                <input type="text" [(ngModel)]="newPassword">
                <div></div>
                <button (click)="changePassword()">Submit</button>
            </div>
            <div *ngIf="errorFlag_passwordIsInvalid" class="auth-error-message auth-error-message-left">Password must be between 8 and 255 characters and must contain at least 1 uppercase a 1 special character</div>
            <div *ngIf="errorFlag_passwordFailedToUpdate" class="auth-error-message">The password failed to update</div>
            <div *ngIf="errorFlag_passwordServiceUnavailable" class="auth-error-message">The service is currently unavailable</div>
            <div *ngIf="successFlag_password" class="auth-success-message">Password was successfully updated</div>
        
            <!-- Verify email status -->
            <div class="admin-dialog-field-row">
                <p class="dialog-header dialog-header-left">Verify Email: </p>
                <button (click)="verifyEmail()">Verify</button>
            </div>
            <div *ngIf="errorFlag_userAlreadyVerified" class="auth-error-message">User is already verified</div>
            <div *ngIf="errorFlag_verificationUpdatedFailed" class="auth-error-message">The email verification status failed to update</div>
            <div *ngIf="errorFlag_verificationServiceUnavailable" class="auth-error-message">The service is currently unavailable</div>
            <div *ngIf="successFlag_emailVerification" class="auth-success-message">Email verification status was successfully updated</div>
            
            
            <div class="admin-dialog-field-row">
                <button (click)="this.dialogRef.close()">Close</button>
            </div>
            
        </mat-dialog-content>    
    </div>
</div>