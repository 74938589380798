<div #infoContainer class="info-container">
    <app-header-info></app-header-info>
    <p>When the game starts, a random note will be generated and drawn to the staff on-screen.
         Based on the note that is created, select the correct note from the provided options.
         When you answer correctly, a new note will be generated and drawn to the screen.
         The note will not change until you answer correctly, no matter how many incorrect responses are submitted.
    </p>
    <div class="gif-image-container">
        <img src="assets/information/GIFs/SI_Notes.gif">
    </div>

    <app-note-difficulty-info></app-note-difficulty-info>
    <app-timer-info></app-timer-info>
    <app-score-info></app-score-info>
    <app-clef-info></app-clef-info>
    <div class="auth-control-container">
        <a (click)="close()">Close</a>
    </div>

</div>
