import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HeaderInfoComponent } from "../../subcomponents/header-info/header-info.component";
import { TimerInfoComponent } from "../../subcomponents/timer-info/timer-info.component";
import { ScoreInfoComponent } from "../../subcomponents/score-info/score-info.component";
import { ClefInfoComponent } from "../../subcomponents/clef-info/clef-info.component";
import { Information } from '../../information';
import { IntervalDifficultyInfoComponent } from "../../subcomponents/difficulty/interval-difficulty-info/interval-difficulty-info.component";


@Component({
  selector: 'app-ear-training-intervals-info',
  standalone: true,
  imports: [HeaderInfoComponent, TimerInfoComponent, ScoreInfoComponent, IntervalDifficultyInfoComponent],
  templateUrl: './ear-training-intervals-info.component.html',
  styleUrl: './../../information.component.css'
})
export class EarTrainingIntervalsInfoComponent extends Information{
    constructor(@Inject(MAT_DIALOG_DATA) payload: any, dialog: MatDialog){
        super(payload, dialog);
        this.child = EarTrainingIntervalsInfoComponent;
        
    }
}
