<div #infoContainer class="info-container">
    <app-header-info></app-header-info>
    <p>When the game starts, a random scale will be generated and drawn to the staff on-screen.
         Based on the notes that comprise the scale, select the most appropriate quality from the provided options.
         The scale will always be ascending.  When you answer correctly, a new scale will be generated and drawn to the screen.
         The scale will not change until you answer correctly, no matter how many incorrect responses are submitted.
    </p>
    <div class="gif-image-container">
        <img src="assets/information/GIFs/SI_Scales.gif">
    </div>

    <app-scale-difficulty-info></app-scale-difficulty-info>
    <app-timer-info></app-timer-info>
    <app-score-info></app-score-info>
    <app-clef-info></app-clef-info>
    <div class="auth-control-container">
        <a (click)="close()">Close</a>
    </div>

</div>